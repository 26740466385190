<template>
  <el-main class="sitemap-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        {{ $t('navbar.SiteMap') }}
      </div>
      <div class="principle-content">
        <div class="content-section">
          <div class="content-desc">
            <b>{{ $t('Navigator.Site_Navigator_Include') }}</b>
          </div>
          <ol>
            <li>{{ $t('Navigator.Site_Navigator_Include_Section.Section1') }}</li>
            <li>{{ $t('Navigator.Site_Navigator_Include_Section.Section2') }}</li>
            <li>{{ $t('Navigator.Site_Navigator_Include_Section.Section3') }}</li>
            <li>{{ $t('Navigator.Site_Navigator_Include_Section.Section4') }}</li>
          </ol>
        </div>
        <div class="content-section">
          <div class="content-title">
            {{ $t('Navigator.Site_Navigator_Description') }}
          </div>
          <div class="content-desc">
            <span>（1）{{ $t('Navigator.Site_Navigator_Block.Upper') }}</span>
            <span>（2）{{ $t('Navigator.Site_Navigator_Block.Center') }}</span>
            <span>（3）{{ $t('Navigator.Site_Navigator_Block.Lower') }} </span>
            <span>（4）{{ $t('Navigator.Site_Navigator_Block.Left') }}</span>
          </div>
          <ul>
            <li>Alt+U：{{ $t('Navigator.Site_Navigator_Shortcut.AltU') }}</li>
            <li>Alt+C：{{ $t('Navigator.Site_Navigator_Shortcut.AltC') }}</li>
            <li>Alt+L：{{ $t('Navigator.Site_Navigator_Shortcut.AltL') }}</li>
            <li>Alt+Z：{{ $t('Navigator.Site_Navigator_Shortcut.AltZ') }}</li>
          </ul>
          <div class="content-desc">
            {{ $t('Navigator.Browser') }}
          </div>
        </div>
      </div>
      <div class="sitemap-content">
        <div class="sitemap-section">
          <div class="sitemap-title">1.{{ $t('navbar.Guidelines') }}</div>
          <div class="sitemap-item">
            <DownloadFileButton
              :button-type="'button'"
              :class-name="'site-map'"
              :aria-label="$t('general.Download')+$t('navbar.Guidelines')+$t('File.File')"
              :file-para="'manual'"
              :file-name="'使用手冊.pdf'"
              :button-content="'1-1 '+$t('navbar.Manual')"
            />
          </div>
          <div class="sitemap-item">
            <router-link :to="{ name: 'Service' }">
              1-2 {{ $t('navbar.DataSpec') }}
            </router-link>
          </div>
          <div class="sitemap-item">
            <a
              href="https://api.data-sports.tw/swagger-ui/#"
              :aria-label="$t('Aria.TargetBlank')+','+$t('Aria.Goto')+'OpenAPI'"
              target="_blank"
            >
              1-3 {{ $t('navbar.OpenAPILink') }}
            </a>
          </div>
          <div class="sitemap-item">
            <router-link :to="{ name: 'FAQ' }">
              1-4 {{ $t('navbar.FAQ') }}
            </router-link>
          </div>
        </div>
        <div class="sitemap-section">
          <div class="sitemap-title">2.{{ $t('navbar.Others') }} </div>
          <div class="sitemap-item">
            <DownloadFileButton
              :button-type="'button'"
              :class-name="'site-map'"
              :aria-label="$t('general.Download')+$t('navbar.Compliance_handbook')+$t('File.File')"
              :file-para="'compliance_handbook'"
              :file-name="'運動數據公益平台個資評估法遵使用手冊.pdf'"
              :button-content="'2-1 '+$t('navbar.Compliance_handbook')"
            />
          </div>
          <div
            v-for="(item, index) in other"
            :key="index"
            class="sitemap-item"
          >
            <router-link :to="{ name: item.routerName }">
              2-{{index+2}}  {{ $t(`navbar.${item.displayName}`) }}
            </router-link>
          </div>
        </div>
        <div class="sitemap-section">
          <div class="sitemap-title">3.{{ $t('navbar.About') }}</div>
          <div
            v-for="(item, index) in about"
            :key="index"
            class="sitemap-item"
          >
            <router-link :to="{ name: item.routerName }">
              3-{{index+1}}  {{ $t(`navbar.${item.displayName}`) }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'
import DownloadFileButton from '@/components/Button/DownloadFileButton.vue'
import { DownloadFile, MenuItem } from '@/Mixins'

export default {
  name: 'SiteMap',
  components: {
    MainContain,
    DownloadFileButton
  },
  mixins: [DownloadFile, MenuItem]
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.sitemap-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .principle-content, .sitemap-content{
    padding: 0.625rem;
  }
  .content-section{
    .content-title{
      font-weight: bold;
      padding-bottom: 1rem;
    }
    li{
      line-height: 1.5;
    }
  }
  .sitemap-content{
    .sitemap-section{
      .sitemap-title{
        background-color: $physicalFitness_turquoise;
        line-height: 3;
        padding: 0 1rem;
        margin: 1rem 0;
      }
      .sitemap-item{
        line-height: 2;
        padding: 0 2rem;
        cursor: pointer;

        a{
          display: block;
        }
      }
      .sitemap-item:hover{
        color: $hover_dark_blue;
        font-weight: bold;
      }
    }
  }
}
</style>
